import { createContext } from "react";

const CustomCtx = createContext({
    theme: 'dark',
    toggleTheme: () => { },
});

const CustomCtxProvider = CustomCtx.Provider
const CustomCtxConsumer = CustomCtx.Consumer


export { CustomCtx, CustomCtxConsumer, CustomCtxProvider }