import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Schema, Table, Badge, IconButton, ButtonToolbar, FlexboxGrid, Form, Button, Panel, List } from "rsuite";
import { GetAllSubscribers, RemoveSubscriber, AddSubscriber } from "../store/reducers/subscriberReducer";
import CloseIcon from '@rsuite/icons/Close';


const model = Schema.Model({
    info: Schema.Types.StringType()
        .isEmail("Enter valid email address")
        .isRequired("This field is required."),
});


const SubscriberList = () => {
    const { subscribers, last_action } = useSelector((e) => e.Subscriber);
    const { Column, Cell, HeaderCell } = Table;
    const dispatch = useDispatch();

    const sub = subscribers || [];
    //let status;
    useEffect(() => {
        const getSub = async () => {
            dispatch(GetAllSubscribers());
        }
        getSub()
    }, [last_action, dispatch]);
    return (
        <Table height={500} data={sub}>
            <Column width={50} align="center" fixed fullText>
                <HeaderCell>Id</HeaderCell>
                <Cell dataKey="notification_subscriber_id" />
            </Column>

            <Column width={100} fixed fullText>
                <HeaderCell>Type</HeaderCell>
                <Cell dataKey="type" />
            </Column>

            <Column width={300} fixed fullText>
                <HeaderCell>Email</HeaderCell>
                <Cell dataKey="subscriber_info" />
            </Column>

            <Column width={100} fixed fullText>
                <HeaderCell>Status</HeaderCell>
                <Cell>
                    {(rowData) =>
                        rowData.is_active === 1 ? (
                            <Badge color="blue" content="Active" />
                        ) : (
                            <Badge color="red" content="InActive"></Badge>
                        )
                    }
                </Cell>
            </Column>

            {/* <Column width={170}>
                <HeaderCell>Change Active Status</HeaderCell>

                <Cell>
                    {(rowData) => {
                        function changeStatus(rowData) {
                            dispatch(UpdateSubscriberStatus(rowData.notification_subscriber_id));
                        }

                        status = rowData.is_active === 1 ? true : false;

                        return (
                            <span>
                                <Toggle
                                    checked={status}
                                    onChange={() => changeStatus(rowData)}
                                />
                            </span>
                        );
                    }}
                </Cell>
            </Column> */}
            <Column width={170}>
                <HeaderCell>Delete</HeaderCell>

                <Cell>
                    {(rowData) => {
                        function removeSubscriber(rowData) {
                            dispatch(RemoveSubscriber(rowData.notification_subscriber_id));
                        }
                        return (
                            <span>
                                <ButtonToolbar>
                                    <IconButton
                                        icon={<CloseIcon />}
                                        color="red"
                                        appearance="primary"
                                        onClick={() => removeSubscriber(rowData)}
                                    />
                                </ButtonToolbar>
                            </span>
                        );
                    }}
                </Cell>
            </Column>
            <Column width={200}>
                <HeaderCell>Created At</HeaderCell>
                <Cell dataKey="created_at" />
            </Column>
            <Column width={200}>
                <HeaderCell>Last Updated</HeaderCell>
                <Cell dataKey="last_updated_at" />
            </Column>
        </Table>
    )
}


const Subscriber = () => {

    const dispatch = useDispatch();
    const formRef = useRef();
    const submitButtonRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({ "type": "email" });
    const handleSubmit = () => {
        if (Object.keys(formError).length > 0) {
            return;
        }
        if (formRef.current.check()) {
            dispatch(AddSubscriber(formValue));
        }
        return;
    };


    return (
        <div className="show-grid">
            <Panel
                shaded
                bordered
                bodyFill
                header="Add New Subscriber"
                style={{
                    display: "inline-block",
                    width: "75vw",
                    height: "auto",
                }}
            >
                <FlexboxGrid style={{ alignItems: "center" }}>
                    <FlexboxGrid.Item colspan={24}>
                        <Form
                            fluid
                            model={model}
                            formValue={formValue}
                            checkTrigger="change"
                            onCheck={setFormError}
                            ref={formRef}
                            onChange={(formValue) => setFormValue(formValue)}

                        >
                            <List bordered>

                                <List.Item >

                                    <Form.Group style={{ display: "flex" }}>

                                        <FlexboxGrid.Item colspan={12}>
                                            <Form.Control
                                                name={'info'}
                                                errorPlacement="bottomStart"
                                                autoComplete="off"
                                                type="text"
                                            />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={2} style={{ marginLeft: "20px" }}>
                                            <Form.ControlLabel >
                                                <Form.Group>
                                                    <ButtonToolbar>
                                                        <Button
                                                            size="md"
                                                            appearance="primary"
                                                            ref={submitButtonRef}
                                                            onClick={handleSubmit}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </ButtonToolbar>
                                                </Form.Group>
                                            </Form.ControlLabel>
                                        </FlexboxGrid.Item>
                                    </Form.Group>

                                </List.Item>
                            </List>
                        </Form>
                    </FlexboxGrid.Item>
                </FlexboxGrid>


            </Panel>
            <Panel
                shaded
                bordered
                bodyFill
                header="Subscribers"
                style={{
                    display: "inline-block",
                    width: "75vw",
                    height: "auto",
                }}
            >
                <SubscriberList />
            </Panel>


        </div >
    )
}


export default Subscriber

