import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Schema, Table, Badge, Toggle, IconButton, FlexboxGrid, Form, Row, Panel, List, Button } from "rsuite";
import { AddNewUser, GetAllUsers, UpdateUserStatus, ResendVerificationLinkDispatch } from "../store/reducers/userReducer";
import PlusIcon from '@rsuite/icons/Plus';
import Popup from "../components/Modal/Modal";


const UserList = () => {
    const { users, last_action } = useSelector((e) => e.User);
    const { Column, Cell, HeaderCell } = Table;

    const dispatch = useDispatch();

    const user = users || [];
    let status;
    useEffect(() => {
        const getSub = async () => {
            dispatch(GetAllUsers());
        }
        getSub()
    }, [last_action]);

    const handleResendVerification = (user_uid) => {
        dispatch(ResendVerificationLinkDispatch(user_uid));
    }
    return (
        <Table height={500} data={user}
            virtualized
            cellBordered
            affixHeader
            affixHorizontalScrollbar
        >
            <Column width={170} align="center" fixed fullText>
                <HeaderCell>Id</HeaderCell>
                <Cell dataKey="user_uid" />
            </Column>

            <Column width={100} fixed fullText>
                <HeaderCell>First Name</HeaderCell>
                <Cell dataKey="first_name" />
            </Column>

            <Column width={100} fixed fullText>
                <HeaderCell>Last Name</HeaderCell>
                <Cell dataKey="last_name" />
            </Column>
            <Column width={100} fullText>
                <HeaderCell>Username</HeaderCell>
                <Cell dataKey="username" />
            </Column>

            <Column width={250} fullText>
                <HeaderCell>Email</HeaderCell>
                <Cell dataKey="email" />
            </Column>
            <Column width={100} fullText>
                <HeaderCell>Account Type</HeaderCell>
                <Cell dataKey="user_type" />
            </Column>
            <Column width={230}>
                <HeaderCell>Is Email Verified</HeaderCell>
                <Cell>
                    {(rowData) => {
                        return (
                            rowData.is_email_verified === 1 ? (
                                <Badge color="blue" content="Yes" />

                            ) : (
                                <>
                                    <Badge color="red" content="No"></Badge>
                                    {rowData.user_type !== 'admin' ?
                                        <Button appearance="link" onClick={() => handleResendVerification(rowData.user_uid)}> Resend Verification Link </Button>
                                        : null}
                                </>
                            ))
                    }
                    }
                </Cell>
            </Column>

            <Column width={100}>
                <HeaderCell>Status</HeaderCell>
                <Cell>
                    {(rowData) =>
                        rowData.is_active === 1 ? (
                            <Badge color="blue" content="Active" />
                        ) : (
                            <Badge color="red" content="InActive"></Badge>
                        )
                    }
                </Cell>
            </Column>
            <Column width={120}>
                <HeaderCell>Change Status</HeaderCell>

                <Cell>
                    {(rowData) => {
                        function changeStatus(rowData) {
                            dispatch(UpdateUserStatus(rowData.user_uid));
                        }

                        status = rowData.is_active === 1 ? true : false;

                        return (
                            <span>
                                {rowData.user_type !== 'admin' ?
                                    <Toggle
                                        checked={status}
                                        onChange={() => changeStatus(rowData)}
                                    /> : null
                                }

                            </span>
                        );
                    }}
                </Cell>
            </Column>
            <Column width={150} fullText>
                <HeaderCell>Created At</HeaderCell>
                <Cell dataKey="created_at" />
            </Column>
            <Column width={150} fullText >
                <HeaderCell>Last Updated</HeaderCell>
                <Cell dataKey="updated_at" />
            </Column>
        </Table >
    )
}


const fields = [
    { label: 'First Name', key: 'first_name', toolTipMsg: "Enter a valid first Name" },
    { label: 'Last Name', key: 'last_name', toolTipMsg: "Enter last name (optional)" },
    { label: 'Username', key: 'username', toolTipMsg: "Enter unique username" },
    { label: 'Email', key: 'email', toolTipMsg: "Enter email address" },
]

const model = Schema.Model({
    first_name: Schema.Types.StringType()
        .pattern(/^[A-Za-z ]+$/, "Enter valid first name")
        .isRequired("This field is required."),
    last_name: Schema.Types.StringType()
        .pattern(/^[A-Za-z]+$/, "Enter valid last name")
        .minLength(5)
        .maxLength(15),
    username: Schema.Types.StringType()
        .pattern(/^[a-zA-Z0-9_.-]+$/, "Enter valid user name, alpahnumeric and special symbols  . _ can also be included")
        .minLength(5)
        .maxLength(15)
        .isRequired("User name is mandatory"),
    email: Schema.Types.StringType()
        .pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Enter Valid email address")
        .isRequired("Email is required"),
});

const AddUser = (props) => {

    const dispatch = useDispatch();
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({});
    const handleSubmit = () => {
        if (Object.keys(formError).length > 0) {
            return;
        }
        if (formRef.current.check()) {
            dispatch(AddNewUser(formValue));
        }
        return;
    };

    return (
        <>
            {props.showPopup ? (

                <Popup Title="Add New User" size="lg" show={props.showPopup} confirmButtonTitle="Submit" onConfirm={handleSubmit}>

                    <FlexboxGrid style={{ alignItems: "center" }}>
                        <FlexboxGrid.Item colspan={24}>
                            <Form
                                fluid
                                model={model}
                                formValue={formValue}
                                checkTrigger="change"
                                onCheck={setFormError}
                                ref={formRef}
                                onChange={(formValue) => setFormValue(formValue)}

                            >
                                <List bordered>
                                    {fields.map((data, i) =>
                                        <List.Item key={i} >

                                            <Form.Group style={{ display: "flex" }}>
                                                <FlexboxGrid.Item colspan={8}><Form.ControlLabel><b>{data.label}</b></Form.ControlLabel></FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={14}>
                                                    <Form.Control
                                                        name={data.key}
                                                        errorPlacement="bottomStart"
                                                        autoComplete="off"
                                                        type="text"

                                                    />

                                                </FlexboxGrid.Item>
                                            </Form.Group>

                                        </List.Item>

                                    )}


                                </List>
                            </Form>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                </Popup>

            ) : null}
        </>
    )
}



const User = () => {
    const { last_action, is_success } = useSelector((e) => e.User);
    const [showPopup, setShowPopup] = useState(false);
    const handleAddUser = () => {
        setShowPopup(true)
    }
    useEffect(() => {
        if (last_action === 'add' && is_success) {
            setShowPopup(false);
        }
    }, [last_action]);
    return (
        <div className="show-grid">

            <Row style={{
                marginRight: "50px",
                float: "right",
                marginTop: "20px",
                marginBottom: "20px"
            }}>
                <IconButton
                    icon={<PlusIcon />}
                    placement="left"
                    color="blue"
                    appearance="primary"
                    onClick={handleAddUser}
                >
                    Add New User
                </IconButton>
            </Row>
            <Panel
                shaded
                bordered
                bodyFill
                header="Streamer Users"
                style={{
                    display: "inline-block",
                    width: "75vw",
                    height: "auto",
                }}
            >
                <UserList />
            </Panel>
            <AddUser showPopup={showPopup} />

        </div >
    )
}

export default User