import React, { useEffect } from 'react'
import { createBrowserRouter, createRoutesFromElements, Route, useLocation } from 'react-router-dom'
import Layout from './Layout'
import { getCookie } from './utils/cookie'
import { NotFound, Login, Dashboard, Channels, ChannelView, ChannelAdd, Subscriber, User, Profile, ForgotPassword, Validation, StreamLogs, EventLogs } from './pages'
import { ValidateLogin, removeLogin } from "./store/reducers/authReducer";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoutes = () => {
    let { userLoggedIn } = useSelector((auth) => auth.Auth)
    const dispatch = useDispatch()
    const location = useLocation();
    useEffect(() => {
        if (!getCookie("_x_gck_streamer")) {
            dispatch({ type: removeLogin.type })
        } else {
            dispatch(ValidateLogin())
        }
    }, [dispatch]);

    if (userLoggedIn === undefined) {
        return null;
    }
    return userLoggedIn
        ? <Outlet />
        : <Navigate to="/login" replace state={{ from: location }} />;
}



const Router = createBrowserRouter(createRoutesFromElements(
    <Route>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Layout />}>
            <Route element={<PrivateRoutes />}>
                <Route path='/channels' element={<Channels />} />
                <Route path='/channel/add' element={<ChannelAdd />} />
                <Route path='/channel/view/:id' element={<ChannelView />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/logs/stream" element={<StreamLogs />} />
                <Route path='/logs/event' element={<EventLogs />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/subscribers" element={<Subscriber />} />
                <Route path="/users" element={<User />} />

            </Route>
            <Route path='/login' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/validation' element={<Validation />} />

        </Route>

    </Route>
))

export default Router