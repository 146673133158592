import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { List, FlexboxGrid, Schema, Form, Panel, Button, ButtonToolbar } from "rsuite";
import { SetNewPasswordDispatch, SetForgotPasswordDispatch, ValidationDispatch } from '../store/reducers/authReducer'


const model = Schema.Model({
    new_password: Schema.Types.StringType()
        .isRequired("This field is required.")
        .minLength(5)
        .maxLength(25),
    confirm_password: Schema.Types.StringType()
        .addRule((value, data) => {

            if (value !== data.new_password) {
                return false;
            }
            return true;
        }, 'Passwords do not match')
        .isRequired('This field is required.')
        .minLength(5)
        .maxLength(25)
});

// function sleep(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

const SetNewPassword = (props) => {
    const { type, token } = props
    const { last_action, is_success } = useSelector((e) => e.Auth)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({});
    const handleSubmit = () => {
        if (Object.keys(formError).length > 0) {
            return;
        }
        if (formRef.current.check()) {
            if (type === 'new_account_verification') {
                dispatch(SetNewPasswordDispatch(formValue, token, type))
            }
            if (type === 'forgot_password') {
                dispatch(SetForgotPasswordDispatch(formValue, token, type))
            }

        }
        return;
    };
    useEffect(() => {
        if ((last_action === 'reset_password' || last_action === 'set_new_password') && is_success) {
            navigate('/login')
        }
        if (last_action === 'validate_token' && !is_success) {
            //sleep(5000)
            navigate('/login')
        }
    }, [last_action, is_success])

    return (
        <>

            <Panel shaded
                bordered
                bodyFill
                header="Set Password"
                style={{
                    display: "inline-block",
                    width: "50vw",
                    height: "auto",
                }}>

                < FlexboxGrid style={{ alignItems: "center" }}>
                    <FlexboxGrid.Item colspan={24}>

                        <Form
                            fluid
                            model={model}
                            onChange={setFormValue}
                            formValue={formValue}
                            checkTrigger="change"
                            onCheck={setFormError}
                            ref={formRef}
                        >
                            <List bordered>
                                <List.Item>
                                    <Form.Group>
                                        <Form.ControlLabel>New Password</Form.ControlLabel>
                                        <Form.Control autoComplete="off" name="new_password" type="password" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.ControlLabel>Confirm Password</Form.ControlLabel>
                                        <Form.Control
                                            name="confirm_password"
                                            type="password"
                                            autoComplete="off"
                                        />
                                    </Form.Group>
                                </List.Item>
                                <List.Item>
                                    <Form.Group>
                                        <ButtonToolbar>
                                            <Button
                                                appearance="primary"
                                                onClick={handleSubmit}
                                            >
                                                Sign in
                                            </Button>
                                            <Button appearance="subtle" onClick={() => navigate('/login')}>
                                                Cancel
                                            </Button>
                                        </ButtonToolbar>
                                    </Form.Group>
                                </List.Item>
                            </List>
                        </Form>
                    </FlexboxGrid.Item>
                </FlexboxGrid>

            </Panel >
        </>
    )
}


const Validation = () => {
    let [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const token = searchParams.get('token')
    const type = searchParams.get('type')
    const dispatch = useDispatch()
    useEffect(() => {
        if ((typeof (token) === 'undefined' || token === '' || token === null) && (typeof (type) === 'undefined' || type === '' || type === null)) {
            navigate('/login')
        }
        dispatch(ValidationDispatch(token, type))
    }, [token, type])

    return (
        <>
            <SetNewPassword token={token} type={type} />
        </>
    )
}

export default Validation