import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from "./apiReducer";

const slice = createSlice({
    name: "logs",
    initialState: {
        last_action: null,
        stream_logs: [],
        event_logs: [],
        is_success: null
    },
    reducers: {
        requestSubmitBegan: (x, y) => {
            x.is_success = null
        },
        streamLogs: (x, y) => {
            const success = y.payload.status;
            if (success) {
                x.is_success = success ? true : false;
                x.stream_logs = y.payload.data;
                x.last_action = 'list_stream_logs'
            }
        },
        eventLogs: (x, y) => {
            const success = y.payload.status;
            if (success) {
                x.is_success = success ? true : false;
                x.event_logs = y.payload.data;
                x.last_action = 'list_event_logs'
            }
        },
        changeLastUserAction: (x, y) => {
            if (y.prev.url.search('create') > -1) {
                x.users.push(y.prev.data)
                x.last_action = 'add'
                x.is_success = y.payload.status ? true : false;
            }
            if (y.prev.url.search('change-status') > -1) {
                x.last_action = 'update'
                x.is_success = y.payload.status ? true : false;
            }
            if (y.prev.url.search('delete') > -1) {
                x.last_action = 'delete'
                x.is_success = y.payload.status ? true : false;
            }
            // if (y.prev.url.search('resend-verification') > -1) {
            //     x.last_action = 'resend_verification'
            //     x.is_success = y.payload.status ? true : false;
            // }

        }

    }
})

export const {
    requestSubmitBegan,
    streamLogs,
    eventLogs,
    changeLastUserAction
} = slice.actions

export default slice.reducer


export const GetStreamLogsDispatch = (channel_uid = null, limit = 20) => async (dispatch, getState) => {
    const url = channel_uid === null ? `logs/stream_logs?limit=${limit}` : `logs/stream_logs?channel_uid=${channel_uid}&limit=${limit}`
    await dispatch(
        apiCallBegan({
            url,
            method: "GET",
            apiOnStart: requestSubmitBegan.type,
            apiOnSuccess: streamLogs.type,
            showSuccessAlert: false,
            showFailedAlert: true,
        })
    );
};


export const GetEventLogsDispatch = (event = null, limit = 100) => async (dispatch, getState) => {
    const url = event === null ? `logs/event_logs?limit=${limit}` : `logs/event_logs?event=${event}&limit=${limit}`
    await dispatch(
        apiCallBegan({
            url,
            method: "GET",
            apiOnStart: requestSubmitBegan.type,
            apiOnSuccess: eventLogs.type,
            showSuccessAlert: false,
            showFailedAlert: true,
        })
    );
};