
import React, { useEffect } from 'react'
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Badge, Panel, InputPicker, Col, Row, Divider } from "rsuite";
import { GetStreamLogsDispatch } from '../store/reducers/logsReducer'
import { GetAllChannels } from '../store/reducers/channelReducer'


const tableHeight = 300
const StreamLogsList = () => {
    let [searchParams] = useSearchParams();
    const id = searchParams.get('id')
    const navigate = useNavigate()
    const { stream_logs } = useSelector((e) => e.Logs);
    const { channels } = useSelector((x) => x.Channel)
    const { Column, Cell, HeaderCell } = Table;
    const data = [{ channel_uid: 'all', channel_name: 'All' }, ...channels].map(item => ({ label: item.channel_name, value: item.channel_uid }));
    const dispatch = useDispatch();


    useEffect(() => {
        const getSub = async () => {
            if (id === null || id === 'all') {
                dispatch(GetStreamLogsDispatch());
            } else {
                dispatch(GetStreamLogsDispatch(id))
            }
        }
        getSub()
    }, [id]);

    useEffect(() => {
        dispatch(GetAllChannels())
    }, [])

    const filterResult = (id) => {
        navigate({
            search: `?${createSearchParams({ filter: true, id })}`,
        })
    }

    // const loadMore = () => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         //fetchData(data.length, 50)
    //         setLoading(false);
    //     }, 1000);
    // };

    // const handleScroll = (x, y) => {
    //     const contextHeight = data.length * 46;
    //     const top = Math.abs(y);

    //     if (contextHeight - top - tableHeight < 300) {
    //         loadMore();
    //     }
    // };
    return (
        <Panel
            shaded
            bordered
            bodyFill
            header="Stream Logs"
            style={{
                display: "inline-block",
                width: "75vw",
            }}
        >
            <Row className='show-grid'>
                <Col xs={20} xsOffset={18}>
                    <p>Filter Results</p>
                    <InputPicker data={data} style={{ width: 224 }} defaultValue={"All"} onSelect={(e) => filterResult(e)} />
                </Col>

            </Row>
            <Divider />
            <Table
                virtualized
                cellBordered
                autoHeight
                affixHeader
                affixHorizontalScrollbar
                shouldUpdateScroll={false}
                height={tableHeight}
                data={stream_logs}
                onRowClick={(data) => { }}
            >
                <Column width={50} align="center" fixed resizable fullText>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey="stream_log_id">
                    </Cell>
                </Column>

                <Column width={300} fixed resizable fullText>
                    <HeaderCell>Channel Id</HeaderCell>
                    <Cell dataKey="channel_uid" />
                </Column>

                <Column width={140} fixed resizable>
                    <HeaderCell>Channel Name</HeaderCell>
                    <Cell dataKey="channel_name" />
                </Column>
                <Column width={200} resizable>
                    <HeaderCell>Edge</HeaderCell>
                    <Cell dataKey="edge" />
                </Column>

                <Column width={100} resizable>
                    <HeaderCell>Status</HeaderCell>
                    <Cell>
                        {(rowData) =>
                            rowData.status === 1 ? (
                                <Badge color="green" content="Reachable" />
                            ) : (
                                <Badge color="red" content="Unreachable"></Badge>
                            )
                        }
                    </Cell>
                </Column>
                <Column width={150} resizable>
                    <HeaderCell>Created At</HeaderCell>
                    <Cell dataKey="created_at" />
                </Column>
                <Column width={150} resizable>
                    <HeaderCell>Last Updated</HeaderCell>
                    <Cell dataKey="updated_at" />
                </Column>
            </Table >
        </Panel>
    )
}


const StreamLogs = () => {

    return (
        <StreamLogsList />
    )
}

export default StreamLogs