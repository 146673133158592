import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./apiReducer";
//import { removeByAttr } from "../../utils";
import _ from "lodash";
const slice = createSlice({
  name: "channels",
  initialState: {
    requestInProcess: false,
    channels: [],
    channel: [],
    totalChannelCount: 0,
    last_action: null
  },
  reducers: {
    requestSubmitBegan: (state, action) => {
      state.requestInProcess = true;
    },
    requestSubmitSuccess: (state, action) => {
      state.isSuccess = action.payload.status ? true : false;
    },
    channelsList: (state, action) => {
      state.isSuccess = action.payload.status ? true : false;
      state.channels = [];
      _.merge(state.channels, action.payload.data);
      state.totalChannelCount = state.channels.length;
      state.last_action = 'channels_list'
    },
    channel: (state, action) => {
      state.isSuccess = action.payload.status ? true : false;
      state.channel = [];
      _.merge(state.channel, action.payload.data);
      state.last_action = 'channel_list'
    },
    removeChannel: (state, action) => {
      state.isSuccess = action.payload.status ? true : false;
      if (action.payload.status) {
        _.merge(state.channels, action.prev.data);
      }
      state.last_action = 'remove'
    },
    addChannel: (state, action) => {
      state.isSuccess = action.payload.status ? true : false;
      if (action.payload.status) {
        // removeByAttr(
        //   state.channelsList,
        //   "channel_uid",
        //   action.prev.url.slice(action.prev.url.indexOf("/"))
        // );
        //state.channel = [];
        state.last_action = 'add'
      }
    },
    requestSubmitCompleted: (state, action) => {
      state.requestInProcess = false;
    }
  },
});

export const {
  requestSubmitBegan,
  requestSubmitSuccess,
  requestSubmitCompleted,
  channel,
  channelsList,
  removeChannel,
  addChannel,
} = slice.actions;

export default slice.reducer;

export const GetAllChannels = () => async (dispatch, getState) => {
  await dispatch(
    apiCallBegan({
      url: `channel/list`,
      method: "GET",
      apiOnStart: requestSubmitBegan.type,
      apiOnSuccess: channelsList.type,
      apiOnCompleted: requestSubmitCompleted.type,
      showSuccessAlert: false,
      showFailedAlert: true,
    })
  );
};



export const GetChannelById = (id) => async (dispatch, getState) => {
  await dispatch(
    apiCallBegan({
      url: `channel/list?channel_uid=${id}`,
      method: "GET",
      apiOnStart: requestSubmitBegan.type,
      apiOnSuccess: channel.type,
      apiOnCompleted: requestSubmitCompleted.type,
      showSuccessAlert: false,
      showFailedAlert: true,
    })
  );
};

export const UpdateChannelStatus =
  (data) => async (dispatch, getState) => {
    await dispatch(
      apiCallBegan({
        url: `channel/status`,
        method: "PUT",
        data,
        apiOnStart: requestSubmitBegan.type,
        apiOnSuccess: requestSubmitSuccess.type,
        apiOnCompleted: requestSubmitCompleted.type,
        showSuccessAlert: true,
        showFailedAlert: true,
      })
    );
  };

export const UpdateChannel = (id, data) => async (dispatch, getState) => {
  await dispatch(
    apiCallBegan({
      url: `channel/update?channel_uid=${id}`,
      method: "PUT",
      data,
      apiOnStart: requestSubmitBegan.type,
      apiOnSuccess: channel.type,
      apiOnCompleted: requestSubmitCompleted.type,
      showSuccessAlert: true,
      showFailedAlert: true,
    })
  );
};

export const RemoveChannel = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `channel/delete?channel_uid=${id}`,
      method: "DELETE",
      apiOnStart: requestSubmitBegan.type,
      apiOnSuccess: removeChannel.type,
      apiOnCompleted: requestSubmitCompleted.type,
      showSuccessAlert: true,
      showFailedAlert: true,
    })
  );
};

export const AddNewChannel = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `channel/add`,
      method: "POST",
      data,
      apiOnStart: requestSubmitBegan.type,
      apiOnSuccess: addChannel.type,
      apiOnCompleted: requestSubmitCompleted.type,
      showSuccessAlert: true,
      showFailedAlert: true,
    })
  );
};


export const RestartStream = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `stream/reboot?channel_uid=${id}`,
      method: "GET",
      apiOnStart: requestSubmitBegan.type,
      apiOnSuccess: addChannel.type,
      apiOnCompleted: requestSubmitCompleted.type,
      showSuccessAlert: true,
      showFailedAlert: true,
    })
  );
};