import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Container, Footer, Notification, toaster, Loader } from "rsuite";
import { hideAlert } from "./store/reducers/alertReducer";
import { SideBar, TopNav } from './components/Header'
import { persistor } from './store/Store'


const d = new Date();
let year = d.getFullYear();

const AuthLayout = (props) => {
    return (
        <Container style={{ minHeight: "100vh" }}>
            {props.Loader ? (
                <Loader backdrop size="lg" content="Loading..." vertical style={{ zIndex: "999" }} />
            ) : null}
            <TopNav {...props} />
            <Outlet />
            <Footer style={{ margin: "0 10px 5px 0px", textAlign: "end" }}>© Streamer 2022 - {year}. All Rights Reserved.</Footer>
        </Container>
    )
}

const DashLayout = (props) => {
    return (
        <Fragment>
            {props.Loader ? (
                <Loader backdrop size="lg" content="Loading..." vertical style={{ zIndex: "999" }} />
            ) : null}
            <SideBar />
            <div style={{
                "left": "261px",
                "position": "absolute",
                "margin": "30px"
            }}>
                <TopNav {...props} />
                <Outlet {...props} />
                <Footer style={{ margin: "0 10px 5px 0px", textAlign: "end", position: "fixed", bottom: 0 }}>© Streamer 2022 - {year}. All Rights Reserved.</Footer>
            </div>

        </Fragment>
    )
}


const Layout = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { pathname } = location

    const dispatch = useDispatch();
    const storeData = useSelector((e) => e);
    const { Auth, Alert, API } = storeData;
    const { showAlert, message, type } = Alert;
    const { inFlight } = API;
    const placement = "topEnd";
    const { userLoggedIn } = Auth


    useEffect(() => {
        toaster.push(notifyBox, { placement });
        if (showAlert)
            setTimeout(() => {
                dispatch({ type: hideAlert.type });
            }, 3000);
    }, [showAlert]);

    useEffect(() => {
        if (pathname === '/') navigate('/dashboard')
    }, [pathname])


    const notifyBox = showAlert ? (
        <Notification type={type} header={type.toUpperCase()} closable>
            {message}
        </Notification>
    ) : (
        <div></div>
    );
    return (

        !userLoggedIn ? (
            <AuthLayout Loader={inFlight}>
                Auth Layout
                <Outlet />
            </AuthLayout>
        ) : (
            <DashLayout Loader={inFlight} userLoggedIn>
                Hello dash layout
                <Outlet Loader={inFlight} />
            </DashLayout>
        )
    )
}
export default Layout