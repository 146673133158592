import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from "./apiReducer";
import { setCookie, getCookie } from "../../utils/cookie";
import storage from 'redux-persist/lib/storage';


const slice = createSlice({
  name: "auth",
  initialState: {
    userLoggedIn: false,
    is_success: false,
    last_action: null
  },
  reducers: {
    validCreds: (x, y) => {
      x.last_action = 'token_check'
      x.is_success = y.payload.status ? true : false;
      x.userLoggedIn = y.payload.status ? true : false;
    },
    createLogin: (x, y) => {
      x.is_success = y.payload.status ? true : false;
      const headers = JSON.parse(y.headers)
      if (y.payload.status && headers["x-auth-key"]) {
        x.userLoggedIn = true;
        //console.log('inside credate loing reducer3', y.payload.status)
        setCookie('_x_gck_streamer', headers['x-auth-key']);
        setCookie('uid', y.payload.data['user_uid']);
        x.last_action = 'login'

      }
    },
    removeLogin: (state, action) => {
      state.userLoggedIn = false;
      state.last_action = 'logout'
      setCookie("_x_gck_streamer", "", 0);
      setCookie('uid', "", 0);
      storage.removeItem('persist:streamerGooick')
      //storage.clear();

    },
    changeLastUserAction: (x, y) => {
      if (y.prev.url.search('forgot-password') > -1) {
        x.last_action = 'forgot_password'
        x.is_success = y.payload.status ? true : false;
      }

      if (y.prev.url.search('delete') > -1) {
        x.last_action = 'delete'
        x.is_success = y.payload.status ? true : false;
      }
      if (y.prev.url.search('reset-password') > -1) {
        x.last_action = 'reset_password'
        x.is_success = y.payload.status ? true : false;
      }
      if (y.prev.url.search('set-new-password') > -1) {
        x.last_action = 'set_new_password'
        x.is_success = y.payload.status ? true : false;
      }
    }
  }
})

export const {
  validCreds,
  createLogin,
  removeLogin,
  changeLastUserAction
} = slice.actions

export default slice.reducer

export const SignInDispatch = (data) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "auth/login",
      method: "POST",
      data,
      withCredentials: false,
      apiOnSuccess: createLogin.type,
      showSuccessAlert: true,
      showFailedAlert: true,
    })
  );
};

export const ValidateLogin = () => async (dispatch, getState) => {
  if (getCookie("_x_gck_streamer")) {

    return dispatch(
      apiCallBegan({
        url: "auth/validate-login",
        method: "POST",
        apiOnSuccess: validCreds.type,
        apiCallFailed: removeLogin.type,
        showSuccessAlert: false,
        showFailedAlert: false,
      })
    )
  }
  return dispatch({ type: removeLogin.type });
};



export const LogoutUser = () => async (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "auth/logout",
      method: "POST",
      apiOnSuccess: removeLogin.type,
      showSuccessAlert: true,
      showFailedAlert: true,
    })
  )
};


export const ForgotPasswordDispatch = (data) => async (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "auth/forgot-password",
      method: "POST",
      data,
      apiOnSuccess: changeLastUserAction.type,
      showSuccessAlert: true,
      showFailedAlert: true,
    })
  )
};

export const SetForgotPasswordDispatch = (data, token, type) => async (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: `auth/reset-password?token=${token}&type=${type}`,
      method: "POST",
      data,
      apiOnSuccess: changeLastUserAction.type,
      showSuccessAlert: true,
      showFailedAlert: true,
    })
  )
};

export const SetNewPasswordDispatch = (data, token, type) => async (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: `auth/set-new-password?token=${token}&type=${type}`,
      method: "POST",
      data,
      apiOnSuccess: changeLastUserAction.type,
      showSuccessAlert: true,
      showFailedAlert: true,
    })
  )
};


export const ValidationDispatch = (token, type) => async (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: `auth/validate-token?token=${token}&type=${type}`,
      method: "GET",
      apiOnSuccess: changeLastUserAction.type,
      showSuccessAlert: false,
      showFailedAlert: true,
    })
  )
};