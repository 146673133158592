import React from "react";
//import { useSelector } from "react-redux";

const Dashboard = () => {
  //let { userLoggedIn } = useSelector((auth) => auth.Auth)
  return (
    <div className="dashboard">
      <div className="show-grid">
        <h4>Dashboard - Nothing here at this moment, Please visit other pages</h4>
      </div>
    </div>
  );
};

export default Dashboard;


