import { combineReducers } from 'redux'
import Alert from './alertReducer'
import API from './apiReducer'
import Auth from './authReducer'
import Channel from './channelReducer'
import Subscriber from './subscriberReducer'
import User from './userReducer'
import Logs from './logsReducer'


const rootReducer = combineReducers({
    Auth, Alert, API, Channel, Subscriber, User, Logs
})

export default (state, action) => {
    return rootReducer(state, action)
}


//export default rootReducer