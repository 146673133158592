import React, { useState, useRef, useEffect } from "react";
import { FlexboxGrid, Panel, List, Schema, Form, ButtonToolbar, Button, Row } from "rsuite";
import { AddNewChannel } from "../store/reducers/channelReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const fields = [
  { label: 'Channel Name', key: 'channel_name', toolTipMsg: "Enter a valid aphabetic channel Name" },
  { label: 'App Name', key: 'app', toolTipMsg: "Enter a valid aphabetic App Name, app name must be unique and will be part of output URL" },
  { label: 'Stream Name', key: 'stream', toolTipMsg: "Enter a valid aphabetic Stream Name, stream name must be unique and will be part of output URL" },
  { label: 'Description', key: 'description', toolTipMsg: "Optional" },
  { label: 'Primary Edge Source', key: 'edge_primary', toolTipMsg: "Enter Valid RTSP url" },
  { label: 'Secondary Edge Source', key: 'edge_secondary', toolTipMsg: "Enter Valid RTSP url" },
]


const model = Schema.Model({
  channel_name: Schema.Types.StringType()
    .pattern(/^[A-Za-z ]+$/, "Enter valid channel name")
    .isRequired("This field is required."),
  app: Schema.Types.StringType()
    .pattern(/^[A-Za-z]+$/, "Enter valid app name")
    .minLength(5)
    .maxLength(15)
    .isRequired("App Name is mandatory"),
  stream: Schema.Types.StringType()
    .pattern(/^[A-Za-z]+$/, "Enter valid stream name")
    .minLength(5)
    .maxLength(15)
    .isRequired("Stream name is mandatory"),
  edge_primary: Schema.Types.StringType()
    .pattern(/(rtsp):(?:([^\s@]+)@)?([^\s:]+)(?::([0-9]+))?(?:(.*))?/, "Enter Valid RTSP compliant URL")
    .isRequired("Primary Source URL is mandatory"),
  edge_secondary: Schema.Types.StringType()
    .pattern(/(rtsp):(?:([^\s@]+)@)?([^\s:]+)(?::([0-9]+))?(?:(.*))?/, "Enter Valid RTSP compliant URL")
    .isRequired("Secondary Source URL is mandatory"),
});

const ChannelAdd = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const submitButtonRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    description: ""
  });
  const { last_action } = useSelector((e) => e.Channel)
  const dispatch = useDispatch()
  const handleSubmit = () => {
    if (Object.keys(formError).length > 0) {
      return;
    }
    if (formRef.current.check()) {
      dispatch(AddNewChannel(formValue));
    }
    return;
  };

  useEffect(() => {
    if (last_action === 'add') navigate('/channels')
  }, [last_action])
  return (
    <div className="channels">
      <div className="show-grid">

        <Panel
          shaded
          bordered
          bodyFill
          header="Add New Channel"
          style={{
            display: "inline-block",
            width: "75vw",
            height: "auto",
          }}
        >
          <FlexboxGrid style={{ alignItems: "center" }}>
            <FlexboxGrid.Item colspan={24}>
              <Form
                fluid
                model={model}
                formValue={formValue}
                checkTrigger="change"
                onCheck={setFormError}
                ref={formRef}
                onChange={(formValue) => setFormValue(formValue)}

              >
                <List bordered>
                  {fields.map((data, i) =>
                    <List.Item key={i} >

                      <Form.Group style={{ display: "flex" }}>
                        <FlexboxGrid.Item colspan={8}><Form.ControlLabel>{data.label}</Form.ControlLabel></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={14}>
                          <Form.Control
                            name={data.key}
                            errorPlacement="bottomStart"
                            autoComplete="off"
                            type="text"

                          />

                        </FlexboxGrid.Item>
                      </Form.Group>

                    </List.Item>

                  )}


                </List>
                <Row style={{ margin: "20px", float: "right" }}>
                  <Form.Group>
                    <ButtonToolbar>
                      <Button
                        size="lg"
                        appearance="primary"
                        ref={submitButtonRef}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </ButtonToolbar>
                  </Form.Group>
                </Row>
              </Form>
            </FlexboxGrid.Item>
          </FlexboxGrid>


        </Panel>
      </div>
    </div>
  );
};

export default ChannelAdd;



{/* <InputGroup inside>
<Input value={inputValue} onChange={setInputValue} size="sm" />
<InputGroup.Addon>
  <Whisper
    placement="bottom"
    speaker={<Tooltip> {data.toolTipMsg}</Tooltip>}
  >
    <InfoRoundIcon />
  </Whisper>
</InputGroup.Addon>
</InputGroup> */}