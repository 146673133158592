import { createSlice } from '@reduxjs/toolkit'


const slice = createSlice({
    name: 'api',
    initialState: {
        inFlight: false,
        APIsuccess: null
    },
    reducers: {
        apiCallBegan: (state) => {
            state.inFlight = true
        },
        apiCallStarted: (state) => {
            state.inFlight = true
        },
        apiCallSuccess: (state) => {
            state.inFlight = false;
            state.APIsuccess = "success";
        },
        apiCallCompleted: (state) => {
            state.inFlight = false;
        },
        apiCallFailed: (state) => {
            state.inFlight = false;
            state.APIsuccess = "failed";
        }
    }

})

export const {
    apiCallBegan,
    apiCallStarted,
    apiCallSuccess,
    apiCallCompleted,
    apiCallFailed
} = slice.actions

export default slice.reducer;

