import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    Panel,
    Form,
    Button,
    ButtonToolbar,
    Content,
    Schema,
} from "rsuite";
import { ForgotPasswordDispatch } from "../store/reducers/authReducer";

const model = Schema.Model({
    email: Schema.Types.StringType()
        .pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Enter Valid email address")
        .isRequired("Email is required"),
});

const ForgotPassword = (props) => {
    const navigate = useNavigate();
    const formRef = useRef();
    const submitButtonRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({});
    const dispatch = useDispatch();


    const handleForgotPassword = () => {
        if (!formRef.current.check()) {
            return;
        }
        if (Object.keys(formError).length > 0) {
            return;
        }
        Object.keys(formValue).forEach((k) => (formValue[k] = formValue[k].trim()));
        dispatch(ForgotPasswordDispatch(formValue));
        navigate('/login')
    };

    return (
        <Content>

            <Panel
                shaded
                bordered
                header={<h4>Forgot P</h4>}
                bodyFill
                style={{
                    display: "inline-block",
                    width: "50vw",
                    height: "auto",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)"

                }}
            >
                <div style={{ margin: "20px" }}>
                    <Form
                        fluid
                        model={model}
                        onChange={setFormValue}
                        formValue={formValue}
                        checkTrigger="change"
                        onCheck={setFormError}
                        ref={formRef}
                    >
                        <Form.Group>
                            <Form.ControlLabel>Enter Email address</Form.ControlLabel>
                            <Form.Control name="email" type="text" />
                        </Form.Group>

                        <Form.Group>
                            <ButtonToolbar>
                                <Button
                                    appearance="primary"
                                    ref={submitButtonRef}
                                    onClick={handleForgotPassword}
                                >
                                    Forgot Password
                                </Button>
                            </ButtonToolbar>
                        </Form.Group>
                    </Form>
                </div>
            </Panel>
        </Content>
    );
};

export default ForgotPassword;