import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "alert",
  initialState: {
    showAlert: false,
    message: null,
    type: "",
    clearTimeout: 4000,
  },
  reducers: {
    showAlert: (state, action) => {
      state.showAlert = action.payload.message ? true : false;
      state.message = action.payload.message || "";
      state.type = action.payload.status === true ? "success" : "error";
    },
    hideAlert: (state, action) => {
      state.showAlert = false;
      state.message = "";
    },
  },
});

export const { showAlert, hideAlert } = slice.actions;

export default slice.reducer;
