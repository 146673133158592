import axios from 'axios'
import * as actions from '../reducers/apiReducer'
import { showAlert } from "../reducers/alertReducer";
import { getCookie } from '../../utils/cookie'
import { removeLogin } from '../reducers/authReducer'

const APIMiddleware = ({ dispatch, getState }) => (next) => async (action) => {

  if (action.type !== actions.apiCallBegan.type) return next(action);
  next(action);
  const {
    url,
    method,
    data,
    headers,
    withCredentials,
    apiOnError,
    propType,
    apiOnSuccess,
    apiOnCompleted,
    apiCallFailed,
    apiOnStart,
    extraPayload = "",
    showSuccessAlert,
    showFailedAlert,
  } = action.payload;
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
  if (apiOnStart) dispatch({ type: apiOnStart, payload: { type: propType } });
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const cookie = getCookie('_x_gck_streamer')
  if (cookie) axios.defaults.headers.common["x-auth-key"] = cookie
  axios.defaults.withCredentials = true

  try {
    const response = await axios.request({
      url,
      method,
      headers,
      timeout: 1000 * 12,
      [dataOrParams]: data,
      withCredentials,
    });

    if (apiOnSuccess) {
      //dispatch({ type: actions.apiCallSuccess.type });
      dispatch({
        type: apiOnSuccess,
        payload: response.data,
        extraPayload,
        prev: {
          url,
          data,
        },
        headers: JSON.stringify(response.headers),
      });
    }

    if (actions.apiCallSuccess) {
      dispatch({
        type: actions.apiCallSuccess.type,
        payload: "Request success",
        prev: {
          url,
          data,
        },
        headers: JSON.stringify(response.headers),
      });
    }

    if (
      (showSuccessAlert && response.data.status) ||
      (showFailedAlert && response.data.status === false)
    ) {
      dispatch({
        type: showAlert.type,
        payload: response.data,
      });
    }
    if (apiOnCompleted) dispatch({ type: apiOnCompleted });
    dispatch({
      type: actions.apiCallCompleted.type,
      payload: "Request Completed",
    });

  } catch (error) {
    dispatch({
      type: actions.apiCallFailed.type,
      payload: { message: "Something went wrong", status: false }
    });

    if (error.response.status === 401) {
      dispatch({
        type: removeLogin.type,
        payload: { message: "Something went wrong", status: false }
      });
      dispatch({ type: showAlert.type, payload: { message: "Access Denied, Please Login again", status: false } });
    }
    //General
    //Specific
    if (apiOnError) dispatch({ type: apiOnError, payload: error.message });
  }
}

export default APIMiddleware