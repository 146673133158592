import React from "react";
import { Modal, Button } from "rsuite";


const Popup = (props) => {
  const [open, setOpen] = React.useState(props.show || false);
  const [size, setSize] = React.useState(props.size || "sm");
  const handleClose = (props) => setOpen(false);
  let { showFooter = true, confirmButtonTitle = "Ok" } = props
  return (
    <Modal size={size} open={open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>{props.Title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      {
        showFooter ?
          <Modal.Footer>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
            <Button onClick={props.onConfirm} appearance="primary">
              {confirmButtonTitle}
            </Button>
          </Modal.Footer> : null
      }

    </Modal>
  );
};

export default Popup;