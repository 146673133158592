import React, { useContext } from 'react';
import { RadioGroup, Radio } from 'rsuite';
import { setCookie } from '../../utils/cookie';
import { CustomCtx } from '../../context/StatusCtx';
import { Icon } from '@rsuite/icons';
import { ReactComponent as Light } from '../../icons/light.svg'
import { ReactComponent as Dark } from '../../icons/dark.svg'

export function SwitchTheme({ onClose }) {
  const { theme, toggleTheme } = useContext(CustomCtx)

  const themesConfig = [
    {
      value: 'light',
      name: 'Light',
      icon: Light
    },
    {
      value: 'dark',
      name: 'Dark',
      icon: Dark
    }
  ];
  const handleChangeTheme = (value) => {
    setCookie('theme', value)
    toggleTheme(value)
  };

  return (
    <RadioGroup className="theme-switch" value={theme} onChange={handleChangeTheme}>
      <p>Choose theme</p>

      {themesConfig.map(item => (
        <div className="theme-item" key={item.value}>
          <div
            className="item-name"
            tabIndex={-1}
            role="button"
            onClick={() => handleChangeTheme(item.value)}
          >
            <Icon as={item.icon} />
            {item.name}
          </div>
          <Radio value={item.value} />
        </div>
      ))}
    </RadioGroup>
  );
}