import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Panel, Input, InputGroup, Col, Row, Divider } from "rsuite";
import { GetEventLogsDispatch } from '../store/reducers/logsReducer'
import SearchIcon from '@rsuite/icons/Search';


const tableHeight = 500
const EventLogsList = (props) => {

    const { Column, Cell, HeaderCell } = Table;
    const { data } = props
    return (

        <Table
            virtualized
            cellBordered
            autoHeight
            affixHeader
            affixHorizontalScrollbar
            shouldUpdateScroll={false}
            height={tableHeight}
            data={data}
        >
            <Column width={50} align="center" fixed resizable fullText>
                <HeaderCell>Id</HeaderCell>
                <Cell dataKey="event_log_id">
                </Cell>
            </Column>

            <Column width={200} fixed resizable fullText>
                <HeaderCell>Event</HeaderCell>
                <Cell dataKey="event" />
            </Column>

            <Column width={500} resizable fullText>
                <HeaderCell>Details</HeaderCell>
                <Cell dataKey="details" />
            </Column>
            <Column width={100} resizable>
                <HeaderCell>Action Performed By</HeaderCell>
                <Cell dataKey="executed_by" />
            </Column>
            <Column width={200} resizable>
                <HeaderCell>Additional Details</HeaderCell>
                <Cell dataKey="additional_info" />
            </Column>


            <Column width={150} resizable>
                <HeaderCell>Timestamp</HeaderCell>
                <Cell dataKey="created_at" />
            </Column>
        </Table >

    )
}


const EventLogs = () => {
    const [value, setValue] = useState('')
    const [data, setData] = useState([])
    const { event_logs } = useSelector((e) => e.Logs);
    const dispatch = useDispatch();
    let new_data;

    useEffect(() => {
        if (value.length > 2) {
            new_data = data.filter(element => element.event.includes(value) > 0);
            setData(new_data)
        } else {
            setData(event_logs)
        }
    }, [value, event_logs])

    useEffect(() => {
        const getSub = async () => {
            dispatch(GetEventLogsDispatch());
        }
        getSub()
    }, []);


    return (
        <Panel
            shaded
            bordered
            bodyFill
            header="Events Log"
            style={{
                display: "inline-block",
                width: "75vw",
            }}
        >
            <Row className='show-grid'>
                <Col xs={20} xsOffset={18}>
                    <p>Filter Results By Events</p>
                    <InputGroup style={{ width: 224 }}>
                        <Input value={value} onChange={(val) => setValue(val)} />
                        <InputGroup.Button>
                            <SearchIcon />
                        </InputGroup.Button>
                    </InputGroup>
                </Col>

            </Row>
            <Divider />
            <EventLogsList data={data} />
        </Panel>
    )
}

export default EventLogs