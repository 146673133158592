import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Content, FlexboxGrid, Grid, Col, IconButton, Row } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import PlayOutlineIcon from "@rsuite/icons/PlayOutline";
import { ChannelWideCard } from "../components/Card/Cards";
import Player from "../components/Player/Player";
import { GetChannelById, RemoveChannel, RestartStream } from "../store/reducers/channelReducer";
import Popup from "../components/Modal/Modal";
import _ from "lodash";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const ChannelView = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //alert(typeof id)
  useEffect(() => {
    // if (!_.isSet(id)){
    //   alert('navigate')
    //   //navigate('/channel')
    // }
    async function fetchData() {
      dispatch(await GetChannelById(id));
    }
    fetchData();
  }, [id]);


  const { channel } = useSelector((e) => e.Channel);
  //const { APIsuccess } = useSelector((e) => e.API)
  const handleDelete = () => {
    setShowPopup(true);
  };
  const deleteChannel = () => {
    async function deleteData() {
      dispatch(await RemoveChannel(id));
      setShowPopup(false);
      await sleep(4000)
      navigate("/channels");
    }
    deleteData();
  };

  const handleRebootStream = () => {
    async function reboot() {
      dispatch(await RestartStream(id));
    }
    reboot();
  }

  return (
    <Container>
      {showPopup ? (
        <Popup Title="Confirmation?" show={showPopup} onConfirm={deleteChannel}>
          <p>Are you sure about this? This action is irreversible.</p>
        </Popup>
      ) : null}
      <Content>
        {Array.isArray(channel) && !channel.length ? (
          <h1>No channel found</h1>
        ) : (
          <div>
            <FlexboxGrid.Item style={{ margin: "20px 0 20px 0" }}>
              <IconButton
                icon={<TrashIcon />}
                placement="left"
                color="red"
                appearance="ghost"
                onClick={handleDelete}
              >
                Delete
              </IconButton>
              <IconButton
                icon={<PlayOutlineIcon />}
                placement="left"
                appearance="primary"
                style={{ marginLeft: "10px" }}
                onClick={handleRebootStream}
              >
                Restart Streaming
              </IconButton>
            </FlexboxGrid.Item>

            <FlexboxGrid>
              {channel.map((data, index) => (
                <FlexboxGrid.Item colspan={24} key={index}>
                  <ChannelWideCard
                    player={
                      <Player
                        Src={data.output_url}
                        Fluid={true}
                        Responsive={true}
                        autoplay={false}
                      />
                    }
                    key={index}
                    width={"100vw"}
                    data={data}
                    isSuccess
                  />
                </FlexboxGrid.Item>
              ))}

            </FlexboxGrid>
            {/* <Row>
              <Grid fluid style={{ margin: "0 0 20px 0", float: "right" }}>
                
              </Grid>
            </Row> */}
          </div>
        )}
      </Content>
    </Container>
  );
};

export default ChannelView;
