import React, { useRef } from "react";
import VideoJS from "./Videojs";

const Player = (props) => {
  const playerRef = useRef(null);
  const { AutoPlay, Responsive, Fluid, Width, Height, Src, Controls } = props;
  const videoJsOptions = {
    autoplay: AutoPlay || true,
    controls: Controls || true,
    responsive: Responsive || true,
    fluid: Fluid || true,
    innerHeight: Height || "auto",
    innerWidth: Width || "auto",
    sources: [{ src: Src, type: "application/x-mpegURL" }],
  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("waiting", () => {
      //player.log("player is waiting");
    });

    player.on("dispose", () => {
      //player.log("player will dispose");
    });
  };

  return <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />;
};

export default Player;
