import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from "./apiReducer";

const slice = createSlice({
    name: "subscriber",
    initialState: {
        last_action: null,
        subscribers: []
    },
    reducers: {
        susbcribers: (x, y) => {
            if (y.payload.status) {
                x.subscribers = y.payload.data
                x.last_action = 'list'
            }
        },
        changeLastSubAction: (x, y) => {
            if (y.payload.status) {
                if (y.prev.url === 'subscriber/add') {
                    x.subscribers.push(y.prev.data)
                    x.last_action = 'add'
                }
                if (y.prev.url.search('change-status') > -1) {
                    x.last_action = 'update'
                }
                if (y.prev.url.search('delete') > -1) {
                    //console.log(x.subscribers.includes(y.prev.data))
                    x.last_action = 'delete'
                }
            }
        }
    }
})

export const {
    susbcribers,
    changeLastSubAction,
} = slice.actions

export default slice.reducer

export const GetAllSubscribers = () => async (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: "subscriber/list",
            method: "GET",
            apiOnSuccess: susbcribers.type,
            showSuccessAlert: false,
            showFailedAlert: true,
        })
    );
};

export const UpdateSubscriberStatus = (id) => async (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `subscriber/change-status?subscriber_id=${id}`,
            method: "GET",
            apiOnSuccess: changeLastSubAction.type,
            showSuccessAlert: true,
            showFailedAlert: true,
        })
    );
};


export const RemoveSubscriber = (id) => async (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `subscriber/delete?subscriber_id=${id}`,
            method: "DELETE",
            apiOnSuccess: changeLastSubAction.type,
            showSuccessAlert: true,
            showFailedAlert: true,
        })
    );
}

export const AddSubscriber = (data) => async (dispatch, getState) => {
    dispatch(
        apiCallBegan({
            url: `subscriber/add`,
            method: "POST",
            data,
            apiOnSuccess: changeLastSubAction.type,
            showSuccessAlert: true,
            showFailedAlert: true,
        })
    );
}