import React, { useState, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom'
import Router from './Router'
import { Provider } from "react-redux";
import Store, { persistor } from './store/Store';
import { getCookie } from "./utils/cookie";
import { CustomProvider } from "rsuite"
import { CustomCtx } from './context/StatusCtx';
import { PersistGate } from 'redux-persist/integration/react'


function App() {
  const [theme, setTheme] = useState('dark');
  const scheme = getCookie('theme')
  useEffect(() => {
    if (scheme !== null || scheme === 'light' || scheme === 'dark') {
      setTheme(scheme)
    }
  }, [scheme])


  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomProvider theme={theme}>
          <CustomCtx.Provider value={{ "theme": theme, toggleTheme: (app) => { setTheme(app) } }}>
            <RouterProvider router={Router} />
          </CustomCtx.Provider>
        </CustomProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
