import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import APIMiddleware from "./middlewares/APImiddleware";
import reducer from "./reducers";
const persistConfig = {
  key: "streamerGooick",
  version: 1,
  storage,
};


const rootReducer = persistReducer(persistConfig, reducer);

const Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })).concat(APIMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});



export default Store;

export let persistor = persistStore(Store);