import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from "./apiReducer";

const slice = createSlice({
    name: "user",
    initialState: {
        last_action: null,
        users: [],
        user_profile: {},
        is_success: null
    },
    reducers: {
        requestSubmitBegan: (x, y) => {
            x.is_success = null
        },
        user: (x, y) => {
            const success = y.payload.status;
            if (success) {
                x.is_success = success ? true : false;
                x.user_profile = y.payload.data[0];
                x.last_action = 'list'
            }
        },
        users: (x, y) => {
            const success = y.payload.status;
            if (success) {
                x.is_success = success ? true : false;
                x.users = y.payload.data;
                x.last_action = 'list'
            }
        },
        changeLastUserAction: (x, y) => {
            if (y.prev.url.search('change-password') > -1) {
                x.last_action = 'password_change'
                x.is_success = y.payload.status ? true : false;
            }
            if (y.prev.url.search('create') > -1) {
                x.users.push(y.prev.data)
                x.last_action = 'add'
                x.is_success = y.payload.status ? true : false;
            }
            if (y.prev.url.search('change-status') > -1) {
                x.last_action = 'update'
                x.is_success = y.payload.status ? true : false;
            }
            if (y.prev.url.search('delete') > -1) {
                x.last_action = 'delete'
                x.is_success = y.payload.status ? true : false;
            }
            // if (y.prev.url.search('resend-verification') > -1) {
            //     x.last_action = 'resend_verification'
            //     x.is_success = y.payload.status ? true : false;
            // }

        }

    }
})

export const {
    requestSubmitBegan,
    user,
    users,
    changeLastUserAction
} = slice.actions

export default slice.reducer


export const AddNewUser = (data) => async (dispatch, getState) => {
    await dispatch(
        apiCallBegan({
            url: "user/create",
            method: "POST",
            data,
            apiOnStart: requestSubmitBegan.type,
            apiOnSuccess: changeLastUserAction.type,
            showSuccessAlert: true,
            showFailedAlert: true,
        })
    );
};

export const GetAllUsers = () => async (dispatch, getState) => {

    await dispatch(
        apiCallBegan({
            url: "user/list",
            method: "GET",
            apiOnStart: requestSubmitBegan.type,
            apiOnSuccess: users.type,
            showSuccessAlert: false,
            showFailedAlert: true,
        })
    );
};

export const GetUser = (user_uid) => async (dispatch, getState) => {

    await dispatch(
        apiCallBegan({
            url: `user/list?user_uid=${user_uid}`,
            method: "GET",
            apiOnStart: requestSubmitBegan.type,
            apiOnSuccess: user.type,
            showSuccessAlert: false,
            showFailedAlert: true,
        })
    );
};

export const UpdateUserStatus = (id) => async (dispatch, getState) => {
    await dispatch(
        apiCallBegan({
            url: `user/change-status?user_uid=${id}`,
            method: "PUT",
            data: { id },
            apiOnStart: requestSubmitBegan.type,
            apiOnSuccess: changeLastUserAction.type,
            showSuccessAlert: true,
            showFailedAlert: true,
        })
    );
};


export const ChangeUserPassword = (data) => async (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "user/change-password",
            method: "POST",
            data,
            apiOnSuccess: changeLastUserAction.type,
            showSuccessAlert: true,
            showFailedAlert: true,
        })
    )
};

export const ResendVerificationLinkDispatch = (id) => async (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: `user/resend-verification?user_uid=${id}`,
            method: "GET",
            apiOnSuccess: changeLastUserAction.type,
            showSuccessAlert: true,
            showFailedAlert: true,
        })
    )
};