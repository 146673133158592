import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Panel,
  Form,
  Button,
  ButtonToolbar,
  Content,
  Schema,
} from "rsuite";
import { SignInDispatch, ValidateLogin } from "../store/reducers/authReducer";
import { getCookie } from "../utils/cookie";


const model = Schema.Model({
  emailorusername: Schema.Types.StringType()
    .minLength(4)
    .isRequired("This field is required."),
  password: Schema.Types.StringType()
    .minLength(5)
    .maxLength(20)
    .isRequired("This field is required."),
});

const Login = () => {
  const { userLoggedIn } = useSelector((e) => e.Auth)

  const navigate = useNavigate();
  const formRef = useRef();
  const submitButtonRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({
    emailorusername: "",
    password: "",
  });
  const dispatch = useDispatch();

  const handleSignin = () => {
    if (!formRef.current.check()) {
      return;
    }
    if (Object.keys(formError).length > 0) {
      return;
    }
    Object.keys(formValue).forEach((k) => (formValue[k] = formValue[k].trim()));
    dispatch(SignInDispatch(formValue));
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  useEffect(() => {
    if (getCookie("_x_gck_streamer")) {
      dispatch(ValidateLogin())
    }
    if (userLoggedIn) navigate('/dashboard')
  }, [userLoggedIn]);


  return (
    <Content>
      <h2
        style={{
          display: "inline-block",
          width: "50vw",
          height: "auto",
          position: "absolute",
          left: "50%",
          top: "26%",
          transform: "translate(-50%, -50%)",
          textAlign: "center"
        }}
      >Streamer</h2>
      <Panel
        shaded
        bordered
        header={<h4>Login</h4>}
        bodyFill
        style={{
          display: "inline-block",
          width: "50vw",
          height: "auto",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"

        }}
      >

        <div style={{ margin: "20px" }}>
          <Form
            fluid
            model={model}
            onChange={setFormValue}
            formValue={formValue}
            checkTrigger="change"
            onCheck={setFormError}
            ref={formRef}
          >
            <Form.Group>
              <Form.ControlLabel>Username or Email</Form.ControlLabel>
              <Form.Control name="emailorusername" type="text" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Password</Form.ControlLabel>
              <Form.Control
                name="password"
                type="password"
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group>
              <ButtonToolbar>
                <Button
                  appearance="primary"
                  ref={submitButtonRef}
                  onClick={handleSignin}
                >
                  Sign in
                </Button>
                <Button appearance="link" onClick={handleForgotPassword}>
                  Forgot password?
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </div>
      </Panel>
    </Content>
  );
};

export default Login;

