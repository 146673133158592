import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { ButtonToolbar, Whisper, Popover, Dropdown, IconButton, Button } from 'rsuite';
import { SwitchTheme } from './SwitchTheme';
import MenuIcon from '@rsuite/icons/Menu';
import { removeLogin, LogoutUser } from '../../store/reducers/authReducer'
import { persistor } from '../../store/Store'


const TopNav = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleLogout = () => {
        dispatch({ type: removeLogin.type })
        dispatch(LogoutUser())
        persistor.purge()
        navigate('/login')
    }
    return (
        <ButtonToolbar className="page-toolbar">

            <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={({ onClose, left, top, className }, ref) => (
                    <Popover ref={ref} className={className} style={{ left, top, width: '200px' }} full>
                        <Dropdown.Menu>
                            <Dropdown.Item panel className="more-actions-panel theme-panel">
                                <SwitchTheme onClose={onClose} />
                            </Dropdown.Item>
                            {props.userLoggedIn ?
                                <>
                                    <Dropdown.Item divider />
                                    <Dropdown.Item panel className="more-actions-panel">
                                        <Link to="/profile" >My Profile</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item panel className="more-actions-panel" style={{ padding: "0px 0 10px 12px" }}>
                                        <Button appearance="link" onClick={handleLogout} style={{ padding: "0px 0px" }}>Logout</Button>
                                    </Dropdown.Item>
                                </>
                                : null}
                        </Dropdown.Menu>
                    </Popover>
                )}
            >
                <IconButton size="sm" appearance="subtle" icon={<MenuIcon />} />
            </Whisper>

        </ButtonToolbar>
    )
}

export default TopNav