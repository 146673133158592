import React, {useEffect} from "react";
import {ChannelCard} from '../components/Card/Cards'
import { FlexboxGrid } from "rsuite";
import { GetAllChannels } from "../store/reducers/channelReducer";
import {useDispatch} from 'react-redux'
import Player from "../components/Player/Player";
import { useSelector } from "react-redux";

const Channels = (props) => {
    const { channels } = useSelector((e) => e.Channel);
    const dispatch = useDispatch();
    useEffect(()=>{
      dispatch(GetAllChannels());
    }, [])
    return (
      <div className="channels">
        <div className="show-grid">
          {!channels.length ? null : (
            <FlexboxGrid >
              {channels.map((data, index) => (
                <FlexboxGrid.Item colspan={12} key={index}  style={{ "margin": "auto", "width":"49%"}}>
                  <ChannelCard
                    Data={data}
                    Player={<Player Src={data.output_url} Fluid={false} AutoPlay={false}/>}
                    Key={index}
                  />
                </FlexboxGrid.Item>
              ))}
            </FlexboxGrid>
          )}
        </div>
      </div>
    );
  };
  
  export default Channels;