import React from "react"
import { Sidenav, Nav } from 'rsuite';
import classnames from 'classnames';
//import BarsIcon from '@rsuite/icons/legacy/Bars'
import scrollTop from 'dom-lib/scrollTop';
import { NavLink } from "react-router-dom"
import DashboardIcon from '@rsuite/icons/Dashboard';
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import SentToUserIcon from '@rsuite/icons/SentToUser';
import AdvancedAnalyticsIcon from '@rsuite/icons/AdvancedAnalytics';
import PlayOutlineIcon from '@rsuite/icons/PlayOutline';
import AbTestIcon from '@rsuite/icons/AbTest';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import MediaIcon from '@rsuite/icons/Media';
import BranchIcon from '@rsuite/icons/Branch';
// function setSidebarScrollTop() {
//   const sidebar = document.getElementById('sidebar');
//   const top = scrollTop(sidebar);

//   sessionStorage.setItem('SIDEBAR-TOP', `${top}`);
// }
let activeStyle = {
  textDecoration: "underline",
};


function initSidebarScrollTop() {
  const sidebar = document.getElementById('sidebar');
  const top = sessionStorage.getItem('SIDEBAR-TOP') || 0;
  if (sidebar) {
    scrollTop(sidebar, +top);
  }
}


const SideBar = (props) => {
  // const location = useLocation()
  // // const [{ route }] = matchRoutes(routes, location)
  // const { pathname } = location;

  const [mediaSidebarShow, setMediaSidebarShow] = React.useState(false);
  const showMediaToggleButton = true;

  React.useEffect(initSidebarScrollTop, []);
  const handleOpenMediaSidebar = React.useCallback(() => {
    setMediaSidebarShow(true);
  }, [setMediaSidebarShow]);

  const handleCloseMediaSidebar = React.useCallback(() => {
    setMediaSidebarShow(false);
  }, [setMediaSidebarShow]);
  return (
    <>
      <div
        className={classnames('rs-sidebar-wrapper fixed', {
          'media-sidebar-show': mediaSidebarShow
        })}
        {...props}
      >
        <Sidenav>
          <div className="title-wrapper">

          </div>
          <Nav
            id="sidebar"
            className="nav-docs"
            vertical

          >

            <Nav.Item>
              <DashboardIcon />
              <NavLink
                to="/dashboard"
                style={({ isActive }) =>
                  isActive ? activeStyle : undefined
                }
              >
                Dashboard
              </NavLink>
            </Nav.Item>
            <Nav.Menu title="Channels" icon={<MediaIcon />}>


              <Nav.Item>
                <PlayOutlineIcon />
                <NavLink
                  to="/channels"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }
                >
                  Channels
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <AddOutlineIcon />
                <NavLink
                  to="/channel/add"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }
                >
                  New Channel
                </NavLink>
              </Nav.Item>
            </Nav.Menu>

            <Nav.Menu title="Logs" icon={<AbTestIcon />}>

              <Nav.Item>
                <AdvancedAnalyticsIcon />
                <NavLink
                  to="/logs/stream"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }
                >
                  Stream Logs
                </NavLink>

              </Nav.Item>
              <Nav.Item>
                <BranchIcon />
                <NavLink
                  to="/logs/event"
                  style={({ isActive }) =>
                    isActive ? activeStyle : undefined
                  }
                >
                  Event Logs
                </NavLink>

              </Nav.Item>
            </Nav.Menu>
            <Nav.Item>
              <UserBadgeIcon />
              <NavLink
                to="/users"
                style={({ isActive }) =>
                  isActive ? activeStyle : undefined
                }
              >
                Users
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <SentToUserIcon />
              <NavLink
                to="/subscribers"
                style={({ isActive }) =>
                  isActive ? activeStyle : undefined
                }
              >
                Subscribers
              </NavLink>
            </Nav.Item>


          </Nav>
        </Sidenav>
        <div
          className={classnames('rs-sidebar-media-backdrop', {
            'media-sidebar-show': mediaSidebarShow
          })}
          onClick={handleCloseMediaSidebar}
        />
      </div>
    </>
  )
}

export default SideBar