export function setCookie(name, value, exdays) {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    value =
      encodeURIComponent(value) +
      (exdays === null ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = name + "=" + value;
  }
  
  export function getCookie(name) {
    let i, x, y;
    const ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
      x = x.replace(/^\s+|\s+$/g, "");
      if (x === name) {
        return decodeURIComponent(y);
      }
    }
    return null;
  }