import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { Schema, IconButton, FlexboxGrid, Form, Row, Panel, List } from "rsuite";
import { GetUser } from "../store/reducers/userReducer";
import { ChangeUserPassword } from '../store/reducers/userReducer'
import PlusIcon from '@rsuite/icons/Plus';
import Popup from "../components/Modal/Modal";
import { getCookie } from '../utils/cookie'

const model = Schema.Model({
    new_password: Schema.Types.StringType()
        .isRequired("This field is required.")
        .minLength(5)
        .maxLength(25),
    confirm_password: Schema.Types.StringType()
        .addRule((value, data) => {

            if (value !== data.new_password) {
                return false;
            }
            return true;
        }, 'Passwords do not match')
        .isRequired('This field is required.')
        .minLength(5)
        .maxLength(25)
});

const ChangePassword = (props) => {

    const dispatch = useDispatch();
    const formRef = useRef();
    const [formError, setFormError] = useState({});
    const [formValue, setFormValue] = useState({});
    const handleSubmit = () => {
        if (Object.keys(formError).length > 0) {
            return;
        }
        if (formRef.current.check()) {
            dispatch(ChangeUserPassword(formValue));
        }
        return;
    };

    return (
        <>
            {props.showPopup ? (

                <Popup Title="Change Password" size="sm" show={props.showPopup} confirmButtonTitle="Submit" onConfirm={handleSubmit}>

                    <FlexboxGrid style={{ alignItems: "center" }}>
                        <FlexboxGrid.Item colspan={24}>
                            <Form
                                fluid
                                model={model}
                                formValue={formValue}
                                checkTrigger="change"
                                onCheck={setFormError}
                                ref={formRef}
                                onChange={(formValue) => setFormValue(formValue)}

                            >
                                <List bordered>
                                    <List.Item>
                                        <Form.Group style={{ display: "flex" }}>
                                            <FlexboxGrid.Item colspan={8}><Form.ControlLabel><b>New Password</b></Form.ControlLabel></FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={14}>
                                                <Form.Control
                                                    name="new_password"
                                                    errorPlacement="bottomStart"
                                                    autoComplete="off"
                                                    type="password"

                                                />
                                            </FlexboxGrid.Item>
                                        </Form.Group>
                                    </List.Item>
                                    <List.Item>
                                        <Form.Group style={{ display: "flex" }}>
                                            <FlexboxGrid.Item colspan={8}><Form.ControlLabel><b>Confirm Password</b></Form.ControlLabel></FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={14}>
                                                <Form.Control
                                                    name="confirm_password"
                                                    errorPlacement="bottomStart"
                                                    autoComplete="off"
                                                    type="password"

                                                />
                                            </FlexboxGrid.Item>
                                        </Form.Group>
                                    </List.Item>

                                </List>
                            </Form>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                </Popup>

            ) : null}
        </>
    )
}

const Profile = () => {
    const { user_profile, last_action, is_success } = useSelector((e) => e.User);
    const [showPopup, setShowPopup] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleChangePassword = () => {
        setShowPopup(true)
    }
    useEffect(() => {
        const uid = getCookie('uid')
        if (typeof uid === 'undefined' || uid === '') {
            navigate('/login')
        }
        if (last_action === 'password_change' && is_success) {
            setShowPopup(false);

        }
        if (last_action === 'update' && is_success) {
            setShowPopup(false);
        }
        dispatch(GetUser(uid))
    }, [last_action]);
    return (
        <div className="show-grid">

            <Row style={{
                marginRight: "50px",
                float: "right",
                marginTop: "20px",
                marginBottom: "20px"
            }}>
                <IconButton
                    icon={<PlusIcon />}
                    placement="left"
                    color="blue"
                    appearance="primary"
                    onClick={handleChangePassword}
                >
                    Change Password
                </IconButton>
            </Row>
            <Panel
                shaded
                bordered
                bodyFill
                header="My Profile"
                style={{
                    display: "inline-block",
                    width: "75vw",
                    height: "auto",
                }}
            >
                <List bordered>
                    <List.Item>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={8}>User ID</FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={14}>{user_profile.user_uid}</FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    <List.Item>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={8}>Name</FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={14}>{user_profile.first_name + user_profile.last_name}</FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    <List.Item>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={8}>Account Type</FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={14}>{user_profile.user_type}</FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    <List.Item>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={8}>Username</FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={14}>{user_profile.username}</FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    <List.Item>
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={8}>Email</FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={14}>{user_profile.email}</FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                </List>
            </Panel>
            <ChangePassword showPopup={showPopup} />

        </div >
    )
}

export default Profile