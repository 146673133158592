import React, { useState } from "react";
import {
  Panel,
  Badge,
  FlexboxGrid,
  List,
  Button,
  IconButton,
  Toggle,
  RadioGroup,
  Radio,
  InputGroup,
  Input,
  Whisper,
  Tooltip,
  Grid,
  Row,
  Col,
  Container,
  ButtonToolbar,
} from "rsuite";
import PlayIcon from '@rsuite/icons/legacy/Play';
import EditIcon from "@rsuite/icons/Edit";
import InfoRoundIcon from "@rsuite/icons/InfoRound";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  UpdateChannelStatus,
  UpdateChannel,
} from "../../store/reducers/channelReducer";
import { useDispatch } from "react-redux";
import { remove } from "lodash";

const ListItem = (props) => {
  return (
    <div>
      <List.Item>
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={12}>{props.Title}</FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={12}>{props.children}</FlexboxGrid.Item>
        </FlexboxGrid>
      </List.Item>
    </div>
  );
};
const ChannelCard = (props) => {
  const { Data, Player } = props;
  const navigate = useNavigate();
  const handleManageButton = (id) => {
    navigate(`/channel/view/${id}`);
  };
  return (

    <Panel
      shaded
      bordered
      bodyFill
      style={{
        display: "inline-block",
        width: '100%',
        padding: "20px"
      }}
    >
      <Container style={{}}>
        {Player ? Player : null}
      </Container>
      <Container style={{ display: "flex", paddingTop: "10%" }}>
        <List>
          <ListItem Title="Channel Name"><b>{Data.channel_name}</b></ListItem>
          <ListItem Title="Current Playing">
            {Data.is_primary_active === 1 ? (
              <Badge content="Primary" color="blue" />
            ) : (
              "Secondary"
            )}
          </ListItem>
          <ListItem Title="App">{Data.app}</ListItem>
          <ListItem Title="Stream">{Data.stream}</ListItem>
        </List>
        <Grid>
          <Row className="show-grid" style={{ marginTop: "15px" }}>

            <ButtonToolbar>
              <IconButton icon={<PlayIcon />}
                placement="right"
                size="xs"
                onClick={() => handleManageButton(Data.channel_uid)}
              >
                Manage
              </IconButton>
            </ButtonToolbar>

          </Row>
        </Grid>
      </Container>

    </Panel>

  );
};

const StringToInput = (props) => {
  const [field, setField] = useState([]);
  const dispatch = useDispatch();

  const { Key, Value, Label, Id } = props;


  const [inputValue, setInputValue] = useState(Value || "");
  let obj = {
    [Key]: inputValue,
  };
  const handleSave = (e, key) => {
    dispatch(UpdateChannel(Id, obj));
    setField(() => remove([field], key));
  };

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={8}>{Label}</FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={14}>
        {field.indexOf(Key) > -1 ? (
          <InputGroup inside style={{ maxWidth: "400px" }}>
            <Input value={inputValue} onChange={setInputValue} size="sm" />
            <InputGroup.Addon>
              <Whisper
                placement="top"
                speaker={<Tooltip> Help information</Tooltip>}
              >
                <InfoRoundIcon />
              </Whisper>
            </InputGroup.Addon>
          </InputGroup>
        ) : (
          Value
        )}
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={2}>
        {field.indexOf(Key) === -1 ? (
          <IconButton
            size="xs"
            icon={<EditIcon />}
            value={Key}
            onClick={() =>
              setField((data) =>
                field.indexOf(Key) === -1 ? [...field, Key] : [...field]
              )
            }
          />
        ) : (
          <IconButton
            size="xs"
            icon={<CheckRoundIcon />}
            value={Key}
            onClick={(e) => handleSave(e, Key)}
          />
        )}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

const ToggleSwitches = ({ Key, Label, Status, Id }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(Status === 1 ? true : false);
  const handleActiveStatus = () => {
    setStatus((e) => !e);
    dispatch(UpdateChannelStatus({ "key_name": Key, "channel_uid": Id }));
  };
  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={8}>{Label}</FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={14}>
        <Toggle size="md" checked={status} onChange={handleActiveStatus} />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

const ChannelWideCard = (props) => {
  let { data, height, width, player, isSuccess } = props;

  const { id } = useParams();

  const [currentPlaying, setCurrentPlaying] = useState(
    data.is_primary_active === 1 ? "primary" : "secondary"
  );
  const handleCameraChange = (value) => {
    setCurrentPlaying(value);
  };

  return (
    <Panel
      shaded
      bordered
      bodyFill
      style={{
        display: "inline-block",
        width: "75vw",
        height: height || "auto",
      }}
    >
      <FlexboxGrid style={{ alignItems: "center" }}>
        <FlexboxGrid.Item colspan={8}>
          {player ? player : null}
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={16}>
          <List bordered>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={8}>Current Playing</FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={14}>
                  <RadioGroup
                    name="radioList"
                    value={currentPlaying}
                    onChange={(value) => {
                      handleCameraChange(value);
                    }}
                  >
                    <FlexboxGrid>
                      <FlexboxGrid.Item colspan={8}>
                        <Radio value="primary" disabled>
                          Primary
                        </Radio>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item colspan={8}>
                        <Radio value="secondary" disabled>
                          Secondary
                        </Radio>
                      </FlexboxGrid.Item>
                    </FlexboxGrid>
                  </RadioGroup>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <ToggleSwitches
                Key="auto_service_check"
                Label="Auto Service Check"
                Id={id}
                Status={data.auto_service_check}
              />
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={8}>Output Address</FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={14}>
                  <Link to={data.output_url}>{data.output_url}</Link>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <StringToInput
                Label="Channel Name"
                Value={data.channel_name}
                Key="channel_name"
                Success={isSuccess}
                Id={id}
              />
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={8}>App Name</FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={14}>{data.app}</FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={8}>Stream Name</FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={14}>{data.stream}</FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={8}>Output Name</FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={14}>{data.output_name}</FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <StringToInput
                Label="Description"
                Value={data.description}
                Key="description"
                Success={isSuccess}
                Id={id}
              />
            </List.Item>
            <List.Item>
              <StringToInput
                Label="Edge Primary"
                Value={data.edge_primary}
                Key="edge_primary"
                Success={isSuccess}
                Id={id}
              />
            </List.Item>
            <List.Item>
              <StringToInput
                Label="Edge Secondary"
                Value={data.edge_secondary}
                Key="edge_secondary"
                Success={isSuccess}
                Id={id}
              />
            </List.Item>
            <List.Item>
              <ToggleSwitches
                Key="is_active"
                Label="Channel Status"
                Id={id}
                Status={data.is_active}
              />
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={8}>
                  Primary Camera Status
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={14}>
                  {data.is_primary_active === 1 ? (
                    <Badge content={`Reachable`} color="blue" />
                  ) : (
                    <Badge content={`Unreachable`} color="red" />
                  )}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={8}>
                  Secondary Camera Status
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={14}>
                  {data.is_secondary_active === 1 ? (
                    <Badge content={`Reachable`} color="blue" />
                  ) : (
                    <Badge content={`Unreachable`} color="red" />
                  )}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
          </List>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Panel>
  );
};

export { ChannelCard, ChannelWideCard };
