import React from 'react';
import { Link } from 'react-router-dom';


function NotFound() {
    const statusCode = 404
    return (
        <>
            <div className="error-wrapper">
                <img alt="404" src="/images/error-404.svg" />
                <h2>"Nothing here, Page Not found" - （${statusCode}）`</h2>
                <div className="nav-help">
                    <Link to='/login'>
                        <a>Take me back to dashboard</a>
                    </Link>
                </div>
            </div>
            <style jsx>{`
        .error-wrapper {
          position: absolute;
          width: 480px;
          left: 50%;
          top: 50%;
          padding: 10px;
          font-size: 14px;
          text-align: center;
          margin-top: -165px;
          margin-left: -230px;
        }
        h2 {
          font-size: 18px;
        }
        .title {
          margin: 0 0 40px;
          color: @H500;
          font-size: 100px;
          font-weight: 700;
        }
        .nav-help {
          cursor: pointer;
          padding: 10px 0;
        }
      `}</style>
        </>
    );
}


export default NotFound;